<template>
    <div data-app>

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>

        <div class="row m-t-40">
            <div class="col-md-6 col-lg-4 col-xlg-4">
                <div class="card">
                    <div class="box bg-info text-center" style="padding-top: 15px;padding-bottom: 15px;">
                        <h1 class="font-light text-white">{{ lotterry_summary.total_tickets_issued }}</h1>
                        <h6 class="text-white">Total Tickets issued</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xlg-4">
                <div class="card">
                    <div class="box bg-primary text-center" style="padding-top: 15px;padding-bottom: 15px;">
                        <h1 class="font-light text-white"> {{ lotterry_summary.total_tickets_today }}</h1>
                        <h6 class="text-white">Tickets today</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xlg-4">
                <div class="card">
                    <div class="box bg-success text-center" style="padding-top: 15px;padding-bottom: 15px;">
                        <h1 class="font-light text-white"> {{ lotterry_summary.total_tickets_this_week }}</h1>
                        <h6 class="text-white">Tickets this week</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <hr/>
            </div>
        </div>

        <div class="row" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;">
            <div class="col-md-2">
                <label><strong>Filter by outlet:</strong></label> <br/>
                <div style="margin-right:20px; width: 100%;" class="btn-group" role="group" >
                    <button  type="button" class="btn btn-secondary dropdown-toggle btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Select Outlet
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 40px, 0px); top: 0px; left: 0px; will-change: transform;">
                        <a   href="#" v-for="outlet in outlets" style="cursor: pointer;" class="dropdown-item">{{ outlet.name }}</a>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <label><strong>Filter by status:</strong></label> <br/>
                <div style="margin-right:20px; width: 100%;" class="btn-group" role="group" >
                    <select class="form-control" v-model="transaction_status" @change="filterByStatus">
                        <option value=""> -- Select status --</option>
                        <option value="valid"> Valid </option>
                        <option value="invalid"> Invalid </option>
                        <option value="pending"> Pending </option>
                    </select>
                </div>
            </div>
            <div class="col-md-7">
                <div class="row" style="margin-bottom: 20px;" >
                    <div class="col-md-8">
                        <label><strong>Filter by date:</strong></label> <br/>
                        <div class="input-daterange-datepicker input-group" >


                            <date-picker   :lang="lang" v-model="from_date" valueType="format" :first-day-of-week="1"></date-picker>

                            <div class="input-group-append">
                                <span class="input-group-text bg-info b-0 text-white">TO</span>
                            </div>
                            <date-picker   :lang="lang" v-model="to_date" valueType="format" :first-day-of-week="1"></date-picker>

                        </div>

                    </div>

                    <div class="col-md-4">
                        <button style="margin-top:30px;" type="button" class="btn btn-danger" @click="filterByDates()"><i class="fa fa-search"></i> Filter </button>

                        <button style="margin-top:30px;" @click="clearFilter()" class="btn btn-warning">Clear Filter</button>

                    </div>
                </div>
            </div>

        </div>

        <br/>

      <b-table striped hover :items="lotteries" :fields="columns">

        <template v-slot:cell(outlet)="data">
          <span>{{ data.value.name }}</span>
        </template>
        <template v-slot:cell(amount)="data">
         KES <span>{{ data.value }}</span>
        </template>

        <template v-slot:cell(transactionId)="data">
          <a href="#" style="text-decoration: underline;">{{ data.value }}</a>
        </template>

        <template v-slot:cell(customer)="data">
          <span>{{ data.value.name }} - {{ data.value.mobileNumber }} </span>
        </template>
        <template v-slot:cell(numOfEntries)="data">
          <a href="#" @click="openLotteryEntriesModal(data.item)">{{ data.item.numOfEntries }}  </a>

        </template>
        <template v-slot:cell(user)="data">
          <span>{{ data.value.name }}</span>
        </template>
        <template v-slot:cell(options)="data">
          <!-- `data.value` is the value after formatted by the Formatter -->
          <b-dropdown text="options">

          </b-dropdown>
        </template>
      </b-table>

      <b-modal id="open-entries" title="Lottery entries" @cancel="true"  centered>
          <ul>
            <li v-for="ticket in ticket_entries">{{ ticket.lotteryNum }}</li>
          </ul>
      </b-modal>




    </div>
</template>

<script>

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    require('vue-toastr/src/vue-toastr.scss');
    import { mapGetters, mapActions } from "vuex";


    export default {
        name: 'lotteries',
        data: () => ({
            transaction_status:"",
            lang: {
                days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                placeholder: {
                    date: 'Select Date',
                    dateRange: 'Select Date Range'
                }
            },
            lotterry_summary:{
              total_tickets_issued:"",
              total_tickets_today:"",
              total_tickets_this_week:""
             },
            table_loading:true,
            tickets:"",
            total_tickets:0,
            total_tickets_today:0,
            total_tickets_this_week:0,
            approval_decline_comments:"",
            from_date:"",
            to_date:"",
            search_phone_number:"",
            rowsPerPageItems: [50, 100, 150, 200],
            selected: [],
            users:[],
            columns:["transactionDate","transactionId","lotteryStatus","outlet","customer","amount","numOfEntries","user","Options"],
            ticket_entries:[],
            isLoading: false,
            fullPage: true,
            loading:false,
            lotteries: [],
             outlets:[],
            csrf: "",
            pagination: {
                current: 1,
                total: 0
            },
            lottery:""
        }),
        mounted() {
            //this.getStations();
            //this.getUsers();
            //this.getTransactions();
            //this.getTicketsSummary();
            //this.getStations();
            this.getOutletData();
            this.getLotteryData();
            this.getLotteryStatsData();
        },
        methods:{
          ...mapActions(["getLotteryTransactions", "getOutlets","getLotteryStats"]),
          openLotteryEntriesModal(lottery){

            this.ticket_entries = lottery.lotteryTickets;

            this.$bvModal.show('open-entries');

          },
          getLotteryStatsData(){
            let self = this;
            this.getLotteryStats()
              .then(function (lotterry_summary) {

                self.lotterry_summary = lotterry_summary;


              })
              .catch(function (error) {
                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              })
          },

          getOutletData(){
            let self = this;
            this.getOutlets()
              .then(function (outlets) {


                self.outlets = outlets;


              })
              .catch(function (error) {
                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              })
          },

          getLotteryData(){
            let self = this;
            this.getLotteryTransactions()
              .then(function (lotteries) {
                self.lotteries = lotteries;
              })
              .catch(function (error) {
                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              })
          },


            clearFilter(){
                location.replace("/lottery");
            },
            viewTickets(tickets){
                this.tickets = tickets;

                this.$refs.viewTickets.open();
            },
            getStations(){
                var self = this;

                axios.get('/getStations')
                    .then(
                        function(response){

                            self.stations = response.data;

                        }
                    )
                    .catch(
                        function(error){
                            console.log(error);
                        });
            },
            getTicketsSummary(){
                var self = this;

                axios.get('/getTicketsStats')
                    .then(
                        function(response){


                            self.total_tickets = response.data.total_tickets;
                            self.total_tickets_today = response.data.total_tickets_today;
                            self.total_tickets_this_week = response.data.total_tickets_this_week;

                        }
                    )
                    .catch(
                        function(error){

                            console.log(error);
                        });
            },
            filterByDates(){


                if(this.from_date != ""){
                    if(this.to_date != ""){


                        var self = this;

                        self.isLoading = true;

                        axios.get('/filterTransactionsByDate/'+this.from_date+'/'+this.to_date)
                            .then(
                                function(response){

                                    self.isLoading = false;

                                    self.lotteries = response.data;

                                }
                            )
                            .catch(
                                function(error){

                                    self.isLoading = false;
                                    console.log(error);
                                });

                    }
                    else{
                        this.$toastr.e("Please select to date.");
                    }
                }
                else{
                    this.$toastr.e("Please select from date.");
                }
            },
            filterTransactionsByStation(station_id){
                var self = this;

                self.isLoading = true;

                axios.get('/filterTransactionsByStation/'+station_id)
                    .then(
                        function(response){

                            self.isLoading = false;

                            self.lotteries = response.data;

                        }
                    )
                    .catch(
                        function(error){

                            self.isLoading = false;
                            console.log(error);
                        });
            },

            filterByStatus(){

                if(this.transaction_status != ""){
                    let self = this;

                    self.isLoading = true;

                    axios.get('/filterByStatus/'+this.transaction_status)
                        .then(
                            function(response){

                                self.isLoading = false;

                                self.lotteries = response.data;

                            }
                        )
                        .catch(
                            function(error){

                                self.isLoading = false;
                                console.log(error);
                            });
                }
                else{
                    alert("Please select transaction status")
                }

            },
            searchName()
            {

                if(this.search_phone_number.length > 2){
                    var self = this;

                    self.isLoading = true;

                    axios.get('/searchTransactionsByPhoneNumber/'+this.search_phone_number)
                        .then(
                            function(response){

                                self.isLoading = false;

                                self.lotteries = response.data;

                            }
                        )
                        .catch(
                            function(error){

                                self.isLoading = false;
                                console.log(error);
                            });
                }
                else{
                    this.$toastr.e("Please enter a valid phone number.");
                }

            },
            getTransactions(){
                var self = this;

                axios.get('/transactions')
                    .then(
                        function(response){

                            self.table_loading = false;

                            self.lotteries = response.data;

                        }
                    )
                    .catch(
                        function(error){
                            self.table_loading = false;
                            console.log(error);
                        });
            },
            confirmDelete(){
                var self = this;

                this.$refs.deleteUser.close();

                self.isLoading = true;

                axios.post('/deleteUser', this.user).then(function (response){


                    self.isLoading = false;

                        if(response.data.status_code == 200){

                            self.$toastr.s("User deleted");

                            self.getUsers();
                        }
                        else{

                            self.$toastr.e(response.data.message);
                        }

                    })
                    .catch(function (error) {

                        self.isLoading = false;

                        self.$toastr.e("An error occurred. Please try again");
                    });

            }
        },
        computed: {
            // a computed getter
            ticketsHtml: function () {
                // `this` points to the vm instance
                return this.tickets.split(",").join("<br/>")
            }
        },
        components:{
            Loading,
           DatePicker
        }
    }
</script>


